/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Jan 1, 2019, 7:03:04 PM
    Author     : czirj
*/

/* Variables */
// COLORS
@blue-gray: #9bb0be; //#a3b1bf;
@gray: #a9a9a9;
@light-gray: #f4f4f4;
//@owner-light: lighten(#8eb2a9,30%);
//@owner-dark: lighten(#8eb2a9,25%);
@gray-darker: #222;
/* Variables end */


h1, h2, h3, h4 {
	font-family: 'ProximaNovaA-Light' !important;
}
h5, h6{
	font-family: 'ProximaNovaA-Bold' !important;
	font-weight: bold;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 32px;
}

h3 {
	 font-size: 28px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 16px;
}

html,
body {
	height: 100%;
}

.wrap {
	min-height: 100%;
	height: auto;
	margin: 0 auto -60px;
	padding: 0 0 60px;
}

.wrap > .container {
	padding: 70px 15px 20px;
}

.footer {
	height: 60px;
	background-color: #f5f5f5;
	border-top: 1px solid #ddd;
	padding-top: 20px;
}

.jumbotron {
	text-align: center;
	background-color: transparent;
}

.jumbotron .btn {
	font-size: 21px;
	padding: 14px 24px;
}

.not-set {
	color: #c55;
	font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, 
a.desc:after {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	padding-left: 5px;
}

a.asc:after {
	content: "\e151";
}

a.desc:after {
	content: "\e152";
}

.sort-numerical a.asc:after {
	content: "\e153";
}

.sort-numerical a.desc:after {
	content: "\e154";
}

.sort-ordinal a.asc:after {
	content: "\e155";
}

.sort-ordinal a.desc:after {
	content: "\e156";
}

.grid-view .filters input,
.grid-view .filters select {
	min-width: 50px;
}

.form-group {
	.hint-block {
		display: block;
		margin: 2px 0 0 2px;
		color: #a9a9a9;
		font-size: 0.8em;
	}
	.help-block {
		display: block;
		margin: 2px 0 0 2px;
		font-size: 0.8em;
	}
}

.hint-block {
	display: block;
	margin: 2px 0 0 2px;
	color: #a9a9a9;
	font-size: 0.8em;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
	padding: 15px;
	border: none;
}

@media (max-width: 767px) {
	.nav li > form > button.logout {
		display: block;
		text-align: left;
		width: 100%;
		padding: 10px 15px;
	}
}

.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
	text-decoration: none;
}

.nav > li > form > button.logout:focus {
	outline: none;
}

/* AdminLTE skin-black mod - bbszabi */
.skin-black {
	.main-header {
		> .logo {
			background-color: @gray-darker !important;
			color: #fff !important;
			border-bottom: 0 solid transparent;
			border-right: 1px solid @gray-darker !important;
			
			&:hover {
				background-color: @gray-darker !important;
			}
		}
		
		.navbar-toggle, 
		.navbar-brand {
			color: #fff !important;
		}
		
		.navbar-brand {
			border-right: 1px solid @gray-darker !important;
		}
		
		.navbar {
			background-color: @gray-darker !important;
			
			.nav {
				> li > a {
					color: @gray !important;
					> .label {
						color: @gray !important;
						font-size: 11px;
					}
				}
				
				> li > a:hover,
				> li > a:active,
				> li > a:focus,
				.open > a,
				.open > a:hover,
				.open > a:focus,
				> .active > a {
					background: #444 !important;
					color: #ddd !important;
				}
			}
			
			.sidebar-toggle {
				color: #fff !important;
				border-right: 1px solid @gray-darker !important;
				
				&:hover {
					background: #444 !important;
					color: #ddd !important;
				}
			}
			
			.navbar-nav > li > a {
				border-right: 1px solid @gray-darker !important;
			}
			
			.navbar-custom-menu .navbar-nav > li > a,
			.navbar-right > li > a {
				border-left: 1px solid @gray-darker !important;
				border-right-width: 0;
			}
		}
		
		li.user-header {
			background-color: #fff !important;
		}
	}
		
	.wrapper, 
	.main-sidebar, 
	.left-side {
		background-color: @gray-darker !important;
	}
	
	.sidebar-menu li {
		&:hover > a,
		&.active > a,
		&.menu-open > a {
			background: #333 !important;
			color: #ddd !important;
			margin: 0 15px;
			padding-left: 0px;
		}
		
		&.active > a {
			border-left-color: transparent !important;
		}
		
		> .treeview-menu {
			margin: 0 0px;
			background: @gray-darker !important;
			padding-left: 0px !important;
			
			> li{
				 > a {
					color: @gray !important;
				}
				&.active > a,
				> a:hover {
					color: #ddd !important;
				}
			}
		}
	}
	
	.sidebar a {
		color: @gray !important;
		
		&.sidebar-toggle{
			color: #fff !important;
			font-size: 23px;
		}
		
		&:hover {
			text-decoration: none;
		}
	}
}

@media (max-width: 767px) {
	.skin-black .main-header > .logo {
		border-bottom: 0 solid transparent;
		border-right: none;
	}
}
/* /AdminLTE skin-black mod - bbszabi */

/* AdminLTE mod - bbszabi */
@media (max-width: 767px) {
	.content, 
	.content-header {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media (min-width: 768px) {
	.content, 
	.content-header {
		padding-left: 30px;
		padding-right: 30px;
	}
}
	
.content-header {
	> .breadcrumb {
		top: 0 !important;
		padding-top: 10px;
	}
}

.login-box {
	margin: 5% auto !important;
}

.text-gray {
	color: #b2b6be !important;
}

.navbar-right {
	margin-right: 0 !important;
}
/* /AdminLTE mod - bbszabi */

/* Select2 mod */
select[readonly].select2-hidden-accessible + .select2-container {
	pointer-events: none;
	touch-action: none;

	.select2-selection {
		background: #eee;
		box-shadow: none;
	}

	.select2-selection__arrow,
	.select2-selection__clear {
		display: none;
	}
}

.select2-container .select2-selection--single .select2-selection__rendered {
	margin-top: 0 !important;
}

.has-error.select2-container--krajee .select2-dropdown, 
.has-error .select2-container--krajee .select2-selection {
	border-color: #dd4b39;
}

.select2-selection,
.select2-container--krajee .select2-selection {
	border: none !important;
	border-radius: 0 !important;
	padding: 20px 24px 10px 12px !important;
	min-height: 50px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.select2-selection--multiple {
	padding-top: 18px !important;
	padding-bottom: 0 !important;
	
	.select2-selection__choice {
		margin: 5px 6px 0 0 !important;
	}
	
	.select2-selection__clear {
		margin-top: 2px !important;
	}
}

.select2-selection__clear {
	margin-right: 0 !important;
}

.select2-container--krajee .select2-selection--multiple .select2-selection__rendered {
	padding: 0px !important;
}

.select2-selection__arrow  {
	top: 9px  !important;
}

.select2-container--bootstrap.select2-container--focus .select2-selection, 
.select2-container--bootstrap.select2-container--open .select2-selection {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	-webkit-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
	border-color: none !important;

}

.select2-container--bootstrap .select2-dropdown {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-color: none;
	overflow-x: hidden;
	margin-top: -1px;
}

.select2-container--bootstrap.select2-container--focus .select2-selection, 
.select2-container--bootstrap.select2-container--open .select2-selection {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	-webkit-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
	border-color: none !important;
}

.select2-container--bootstrap .select2-dropdown {
	border-color: #ddd !important;
	border-radius: 0 !important;
}

.select2-container--krajee .select2-selection--single .select2-selection__arrow {
	border-left: none;
}
/* /Select2 mod */

/* bbszabi */
.login-page .alert {
	text-align: center !important;
}

.login-language {
	text-align: center !important;
	margin-bottom: 20px;
}

.navbar .divider-v {
	height: 40px;
	width: 1px;
	margin: 5px 0px;
	overflow: hidden;
	background-color: #9d9d9d;
}

.sidebar .divider {
	height: 1px;
	margin: 0px 15px;
	overflow: hidden;
	background-color: #9d9d9d;
	&:after{
		content: none;
	}
}

.skin-black .sidebar-menu li.disabled a {
	color: #9d9d9d !important;
}

@media (max-width: 767px) {
	.navbar .divider-v {
		border-width: 0 0 1px 0 ;
		border-style: solid;
		height: 0px;
		margin: 5px 5px;
	}
}

.navbar .language-chooser, 
.navbar .language-chooser .dropdown-menu {
	min-width: 60px;
}

.width-compact {
	white-space: nowrap !important;
	width: 1px !important;
}

form div.required label.control-label:after {
	content: " * ";
	color: red;
}

.tooltip-hover {
	color: orange !important;
	cursor: default !important;
}
.tooltip-inner {
	text-align: left;
}

.modal-view-content {
	margin-top: -30px !important; /* reduce view page title top gap */
}

.width-full {
	width: 100% !important;
}

.margin-top-0 {
	margin-top: 0 !important;
}

.margin-top-5 {
	margin-top: 5px !important;
}

.margin-top-10 {
	margin-top: 10px !important;
}

.margin-top-15 {
	margin-top: 15px !important;
}

.margin-top-20 {
	margin-top: 20px !important;
}

.margin-top-25 {
	margin-top: 25px !important;
}

.margin-top-30 {
	margin-top: 30px !important;
}

.margin-bottom-0 {
	margin-bottom: 0 !important;
}

.margin-bottom-5 {
	margin-bottom: 5px !important;
}

.margin-bottom-10 {
	margin-bottom: 10px !important;
}

.margin-bottom-15 {
	margin-bottom: 15px !important;
}

.margin-bottom-20 {
	margin-bottom: 20px !important;
}

.margin-bottom-25 {
	margin-bottom: 25px !important;
}

.margin-bottom-30 {
	margin-bottom: 30px !important;
}

.input-group-label {
	background-color: #eee !important;
}

.input-group-addon {
	border: none;
	background-color: lighten(@gray, 25%) !important;
	
	label {
		font-weight: normal !important;
		margin-bottom: 0;
	}
}

.input-group { // Select2 field display bug fix (when above fiedl has error)
	clear: both;
}

.krajee-datepicker {
	&:not([disabled]) {
		background-color: #fff !important;
		cursor: default !important;
	}
	&[readonly] {
		cursor: pointer !important;
	}
	&[disabled] {
		cursor: not-allowed !important;
	}
}
.datepicker { // belongs to krajee-datepicker
	.day {
		&.disabled {
			color: @gray !important;
			opacity: 0.65 !important;
		}
		&.today {
			background-color: lighten(@gray, 20%);
			&:hover {
				background-color: lighten(@gray, 10%);
			}
		}
	}
	.datepicker-switch {
		background-color: lighten(@blue-gray, 20%);
		&:hover {
			background-color: lighten(@blue-gray, 10%);
		}
	}
}

//.kv-grid-table th {
//	vertical-align: middle !important;
//}

.kv-grid-table tbody td.col-buttons a {
	color: @gray-darker;
}

.kv-grid-table tbody a:hover {
	color: lighten(@gray-darker, 25%);
}

.fileinput-hide-empty-preview .file-input-ajax-new .file-preview { /* hide empty preview for file-input-ajax-new, too */
	display: none !important;
}

.text-normal {
	font-weight: normal !important;
	font-style: normal !important;
}

.text-disabled {
	font-style: italic !important;
	opacity: 0.65 !important;
	filter: alpha(opacity=65) !important;
}

.label-alt {
	font-weight: normal !important;
	font-style: italic !important;
}

.block {
	display: block !important;
}

.white-space-normal {
	white-space: normal !important;
}

.strong, 
.bold {
	font-weight: bold !important;
}

.checkbox-compact {
	height: 46px;
	padding-top: 2px;
	.checkbox {
		margin-top: 10px !important;
	}
}

.checkbox-list-inline-compact {
	.checkbox-inline {
		padding-top: 0;
	}
}

.text-input-compact {
	input[type=text] {
		height: 34px !important;
		padding-top: 10px !important;
	}
	.input-group-addon {
		padding: 6px 12px !important;
	}
}

/*
.radio-inline, 
.checkbox-inline {
	height: 34px !important;
	margin-bottom: 0 !important;
	margin-top: 0 !important;
	padding-bottom: 7px !important;
	padding-top: 7px !important;
}
*/
.radio, 
.checkbox {
	padding-bottom: 4px !important;
}

.disabled .radio label, 
.disabled .checkbox label {
	cursor: not-allowed;
}

.radio-list-buttons {
	.btn-group label {
		padding-left: 28px !important;
	}
	
	input[type=radio] {
		position: absolute;
		left: 10px;
	}
	
	label.bg-success, 
	label.bg-danger, 
	label.bg-info, 
	label.bg-warning, 
	label.bg-primary {
		border-color: #ddd !important;
		color: #333 !important;
	}
	
	.has-error {
		label.bg-success, 
		label.bg-danger, 
		label.bg-info, 
		label.bg-warning, 
		label.bg-primary {
			color: #333 !important;
		}
		label.btn-default {
			color: #444 !important;
		}
	} 
	.has-success {
		label.bg-success, 
		label.bg-danger, 
		label.bg-info, 
		label.bg-warning, 
		label.bg-primary {
			color: #333 !important;
		}
		label.btn-default {
			color: #444 !important;
		}
	}
}

input[type=checkbox][readonly] {
	filter: alpha(opacity=60);
	opacity: .6;
	cursor: not-allowed;
}

.radio-list-compact {
	.radio:first-child {
		margin-top: 0;
	}
}

/*@media (max-width: 479px) {
	.navbar-user-name {
		display: none;
	}
}*/

.box-solid {
	border: none !important;
	
	.box-header {
		color: #fff !important;
		padding: 10px 20px;
		
		h4.box-title {
			font-size: 16px;
		}
		
		h3.box-data {
			font-size: 24px;
			font-weight: bold;
			margin: -3px 0 0 0; 
		}
		
		.box-header-icon {
			width: 40px;
			height: 40px;
			border-radius: 20px;
			margin: 2px 0;
			text-align: center;
			
			.pa {
				font-size: 1.7em;
				line-height: 43px;
			}
		}
		
		.box-header-link {
			display: block;
			background: #fff;
			opacity: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0; left: 0;
		}
			
			&:hover {
				filter: brightness(98%) saturate(117%);
			}
	}

	.box-body {
		padding: 15px 20px;
		
		.box-section-title {
			display: block;
			margin-bottom: 10px;
			text-align: center;
			font-family: 'ProximaNovaA-Light';
			font-size: 16px;
		}
		
		.row {
			margin: 0 -5px 10px -5px;
			
			> div[class*=col-] {
				padding: 0 5px 0 5px;
			}
		}
		
		.btn-box-info {
			display: block;
			font-family: 'ProximaNovaA-Regular';
			text-transform: none;
			line-height: 1.1;
			white-space: normal;
			padding: 2px 12px;
			
			.btn-box-data {
				display: block;
				font-size: 22px;
				white-space: nowrap;
				margin: 0;
				padding: 0;
			}
			
			&.btn-default {
				background-color: #f4f4f4 !important;
				border-color: #f4f4f4 !important;
				
				.btn-box-data {
					color: @gray-darker !important;
				}
				&.disabled .btn-box-data {
					color: #94a5b5 !important;
				}
				&:hover {
					color: #333 !important;
					background-color: #e7e7e7 !important;
					border-color: #e7e7e7 !important;
				}
			}
		}
		
		.box-info {
			display: block;
			border: 1px solid #f4f4f4;
			color: #a9a9a9;
			font-family: 'ProximaNovaA-Regular';
			text-transform: none;
			text-align: center;
			line-height: 1.1;
			white-space: normal;
			padding: 2px 12px;
			
			.box-data {
				display: block;
				color: @gray-darker;
				font-size: 22px;
				white-space: nowrap;
				margin: 0;
				padding: 0;
				
				&.disabled {
					color: #a9a9a9;
				}
			}
		}
	}
	
	.box-footer {
		border-top: none;
		padding: 0 30px 25px 30px;
	}
	
	&.box-default .box-header{
		background-color: @gray !important;
		
		.box-header-icon {
			background-color: lighten(@gray, 25%);
			.pa {
				color: @gray !important;
			}
		}
	}
	
	&.box-default.box-disabled {
		cursor: not-allowed !important;
		opacity: 0.4;
		
		* {
			pointer-events: none;
		}
	}
	
	&.box-primary .box-header {
		.box-header-icon {
			background-color: lighten(#94a5b5, 25%);
			.pa {
				color: #94a5b5 !important;
			}
		}
	}
	
	&.box-success .box-header {
		.box-header-icon {
			background-color: lighten(#8eb2a9, 25%);
			.pa {
				color: #8eb2a9 !important;
			}
		}
	}
	
	&.box-info .box-header {
		.box-header-icon {
			background-color: lighten(#0cf, 25%);
			.pa {
				color: #0cf !important;
			}
		}
	}
	
	&.box-warning .box-header {
		.box-header-icon {
			background-color: lighten(#fc3, 25%);
			.pa {
				color: #fc3 !important;
			}
		}
	}
	
	&.box-danger .box-header {
		.box-header-icon {
			background-color: lighten(#f33, 25%);
			.pa {
				color: #f33 !important;
			}
		}
	}
}

.input-group-btn .btn-clear { // clear buttons addon in simple text inputs
	border-color: #fff;
	background-color: #fff;
	font-weight: bold;
	
	&:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
}

hr.form-rows-separator {
	margin: 0 0 5px 0;
}

.form-control {
	&.form-control-nolabel {
		padding-top: 10px;
	}
}

.fileinput-center {
	.file-preview-frame {
		display: inline-block;
		float: none;
	}
}

.fileinput-readonly {
	&.fileinput-single {
		border: none;
		.file-drop-zone {
			padding: 0;
		}
		.file-preview-frame {
			margin: 0;
		}
	}
	&.file-preview {
		padding: 0;
	}
	.file-drop-zone {
		border: none;
		margin: 0;
	}
}

.radio-list-images,
.checkbox-list-images {
	.radio, 
	.checkbox {
		margin-top: 0;
		input[type=radio],
		input[type=checkbox] {
			position: relative;
			margin-right: 5px;
		}
		img {
			@media (max-width: 767px) {
				width: 250px;
			}
			@media (min-width: 768px) {
				width: 300px;
			}
			display: inline-block;
		}
		.option-label {
			display: block;
			margin-top: 5px;
			font-weight: bold;
			min-height: 40px;
		}
	}
	.help-block {
		margin-bottom: 20px;
	}
}

.wizard-accordion-top {
	margin-bottom: 10px;
}

.wizard-accordion-bottom {
	margin-top: 10px;
}

.wizard-accordion-item-active, 
.wizard-accordion-item-active:active {
	color: #333;
	display: block;
	background-color: lighten(@gray, 10%);
	border: 1px solid darken(@gray, 5%);
	padding: 5px 5px 5px 20px;
//	font-size: 1.1em;
	font-weight: bold;
	text-indent: -7px;
	
	&:active,
	&:focus,
	&:visited {
		color: #333;
	}
	
	&:hover {
		color: #333;
		background-color: lighten(@gray, 15%);
		border: 1px solid darken(@gray, 5%);
	}
}

.wizard-accordion-item-inactive {
	color: darken(@gray, 7%);
	display: block;
	background-color: lighten(@gray, 10%);
	border: 1px solid darken(@gray, 5%);
	padding: 5px 5px 5px 20px;
//	font-size: 1.1em;
	font-weight: bold;
	text-indent: -7px;
}

.slider-input {
	padding: 5px 10px 0 10px;
	
	label.control-label {
//		bottom: -40px;
//		padding-left: 2px;
//		text-align: center;
		padding-left: 0;
		margin-bottom: -10px;
		white-space: nowrap;
	}
	.slider {
		&.slider-horizontal {
			margin-top: 20px;
			width: 100%;
		}
		&.slider-disabled {
			.slider-handle, 
			.slider-track-low, 
			.slider-selection,
			.slider-track-high{
				background: #ddd;
			}
			.slider-tick {
				background: #ddd;
				&.in-selection {
					background: #ddd;
				}
			}
		}
		.slider-handle {
			background: @blue-gray;
		}
		.slider-tick {
			background: #fff;
			&.in-selection {
				background: @gray;
			}
		}
		.slider-tick-label-container {
			.slider-tick-label:nth-child(1) {
				padding-left: 20px;
			}
			.slider-tick-label:nth-child(2) {
				padding-right: 20px;
			}
		}
		.slider-tick-label {
			color: @gray;
			padding-top: 0;
		}
		.slider-selection {
			background: @gray;
		}
		.slider-track-high {
			background: #fff;
		}
		.tooltip.top {
			background: @gray-darker;
			margin-bottom: 0;
			margin-top: -30px;
			padding: 0;
			
			.tooltip-arrow {
				bottom: -5px;
			}
			.tooltip-inner {
				font-size: 14px;
			}
		}
	}
	.help-block {
		display: none;
	}
	
	.slider-display {
		margin-bottom: 20px;
		@media (max-width: 991px) {
			margin-bottom: 10px;
		}
	}
}

table.slider-override {
	width: 100%;
	margin: 18px 0 0 0;
	// less than 992px
	@media (max-width: 991px) {
		margin: 0 0 10px 5px;
	}
	.slider-override-field {
		padding-left: 10px;
	}
}

table.align-top > thead > tr > th,
table.align-top > tbody > tr > th,
table.align-top > tfoot > tr > th,
table.align-top > thead > tr > td,
table.align-top > tbody > tr > td,
table.align-top > tfoot > tr > td, 
td.align-top {
  vertical-align: top;
}

label.control-label {
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.dropdown-menu {
	.dropdown-header {
		font-size: 14px;
		font-weight: bold;
		text-align: center;
	}
}

.list-item {
	text-indent: -10px;
	margin-left: 10px;
}

label.freeform-checkbox {
	margin-top: 5px;
	input[type=checkbox] {
		margin: -2px 0 0 0;
		vertical-align: middle;
	}
}

/* /bbszabi */

/* csaba */
.table-striped{
//	tbody{
//		tr.owner{
//			background-color: @owner-dark;
//			&:nth-of-type(2n+1){
//				background-color: @owner-light;
//			}
//		}
//	}
	
	&.table-i18n {
		tbody{
			tr:nth-of-type(2n){
				td {
					border-top: none;
				}
			}
		}
	}
}

//.owner-sample{
//	width: 20px;
//	height: 20px;
//
//	display: inline-block;
//	border: 1px solid #999;
//	&.dark{
//		background-color: @owner-dark;
//	}
//	&.light{
//		background-color: @owner-light;
//	}
//}

td.kv-group-even{
//	background-color: @owner-dark !important;
//	background-color: #fff !important;
	background-color: transparent !important;
}
td.kv-group-odd{
//	background-color: @owner-light !important;
//	background-color: @light-gray !important;
	background-color: transparent !important;
}

.content-wrapper {
	background-color: @light-gray;
}

.kv-grid-group-row {
	td{
		text-align: center;
		font-size: 2em;
	}
}

.fp-ic-big {
	transform: scale(1.4);
}
[class*="fp-ic"] {
	display: inline-block;
	background: url(/img/icons.svg);
	background-position-x: 0%;
	background-position-y: 0%;
	width: 26px;
	height: 26px;
}

.fp-ic-iguana {
	background-position: 0 -75px;
	margin-bottom: -6px;
	margin-left: 30px;
}

.red-circle{
	color: #f00;
}

.ui-datepicker{
	z-index: 3 !important;
}

.btn-remove-item {
	margin: 0 0 5px 0 !important;
	font-size: 30px !important;
	font-family: 'ProximaNovaS-regular' !important;
	background: @blue-gray !important;
	color: #fff !important;
	padding: 0px 2px !important;
	line-height: 26px !important;
	&:hover{
		background: lighten(@blue-gray, 5%) !important;
		color: #fff !important;
	}
}

@import "pa-icons.less";
//@import "switch.less";
//@import "checkbox.less";
//@import "radio.less";

//force calculation image
.calculation-cable-image-container {
	position: relative;
	
	.length_segment {
		position: absolute;
		top: 15%;
		left: 47%;
		font-weight: bold;
	}
	.length_span {
		position: absolute;
		top: 21%;
		left: 47%;
		font-weight: bold; 
		padding: 4px;
	}
	.deflection_distance {
		position: absolute;
		top: 40.5%;
		left: 26%;
		font-weight: bold; 
		padding: 3px;
	}
	.deflection_angle {
		position: absolute;
		top: 34.5%;
		left: 46%;
		font-weight: bold; 
		padding: 2px;
	}
}
