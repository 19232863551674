.pa {
	display: inline-block;
	width: 1em;
	height: 1em;
	font-size: 1em;
	
	svg {
		display: inline-block;
		width: inherit;
		height: inherit;
		margin-bottom: -2px;
		stroke-width: 0;
		stroke: currentColor;
		fill: currentColor;
	}
}

/* 
Individual icon size adjustments
Individual icon width (em): svg-width/svg-height. Test how it looks in page and adjust if needed.
*/
.pa-building {
	width: 0.81em;
}

.pa-cart {
	width: 1.3em;
}

.pa-certificate {
	width: 0.69em;
}

.pa-certificate-o {
	width: 0.68em;
}

.pa-check {
	width: 1.2em;
}

.pa-check-document-o {
	width: 1.41em;
}

.pa-check-file-o {
	width: 0.79em;
}

.pa-check-square-o {
	width: 0.98em;
}

.pa-comment-o {
	width: 0.96em;
}

.pa-document-text {
	width: 0.79em;
}

.pa-document-text-o {
	width: 0.78em;
}

.pa-download-o {
	width: 1.02em;
}

.pa-eye-open-o {
	width: 1.6em;
}

.pa-file, 
.pa-file-doc, 
.pa-file-excel, 
.pa-file-pdf, 
.pa-file-ppt {
	width: 0.75em;
}

.pa-folder-open-o {
	width: 1.28em;
}

.pa-gears {
	width: 1.03em;
}

.pa-installation {
	width: 1.11em;
}

.pa-language {
	width: 1.21em;
}

.pa-list-alt {
	width: 1.3em;
}

.pa-magnifyer-o {
	width: 0.95em;
}

.pa-question {
	width: 0.64em;
}

.pa-refresh {
	width: 1.14em;
}

.pa-reply-o {
	width: 1.24em;
}

.pa-shield-o {
	width: 0.81em;
}

.pa-sliders {
	width: 1.04em;
}

.pa-tag-o {
	width: 1.4em;
}

.pa-tools-o {
	width: 1.03em;
}

.pa-transfer {
	width: 1.23em;
}

.pa-trash-o {
//	width: 0.82em;
	width: 0.9em;
	height: 1.1em;
}

.pa-undo {
	width: 1.2em;
	svg {
		margin-bottom: -1px;
		top: 0px !important;
	}
}

.pa-users {
	width: 1.5em;
}
/* /Individual icon size adjustments */

/* Must come AFTER the Individual icon size adjustments */
/*.pa svg {
	width: inherit;
	height: inherit;
}*/

.pa-lg {
/*	width: 1.33em;
	height: 1.33em;*/
	font-size: 1.33em;
}

.pa-2x {
/*	width: 2em;
	height: 2em;*/
	font-size: 2em;
}

.pa-3x {
/*	width: 3em;
	height: 3em;*/
	font-size: 3em;
}
/* /Must come AFTER the Individual icon size adjustments */

/* Left menu icons */
.sidebar-menu .pa {
	width: 20px;
}

.kv-grid-table .pa {
	font-size: 1.1em;
}
/* /Left menu icons */

// icons
/*
	&.pa-comment{
		background-image: url('/img/icon/comment.png');
	}
	&.pa-create-plus{
		background-image: url('/img/icon/create-plus.png');
	}
	&.pa-download{
		background-image: url('/img/icon/download.png');
	}
	&.pa-exclamation-sign{
		background-image: url('/img/icon/exclamation-sign.png');
	}
	&.pa-language{
		background-image: url('/img/icon/language.png');
	}
	&.list-alt{
		background-image: url('/img/icon/list-alt.png');
	}
	&.pa-refresh{
		background-image: url('/img/icon/refresh.png');
	}
	&.pa-reply{
		background-image: url('/img/icon/reply.png');
	}
	&.pa-shield{
		background-image: url('/img/icon/shield.png');
	}
	&.pa-transfer{
		background-image: url('/img/icon/transfer.png');
	}
	&.pa-undo{
		background-image: url('/img/icon/undo.png');
	}
}
*/
