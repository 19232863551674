@import "../../common/less/inc/fallprotec.less";
#front_bg{
    position: fixed;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    background-size: cover;
    transition: 1s opacity;
    z-index: -20000;
}




.login-box{
    background: rgba(255, 255, 255, 0.7);
    position: relative;
    width: 610px;
    padding: 60px 75px;
    @media (max-width: 767px) {
        width: 320px;
        padding: 5px;
    }

    .login-box-body{
        padding: 0 20px;
        .login-box-msg{
            padding: 0 0 20px 0;
            font-size: 16px;
            color: #222;
        }
        h3{
            font-family: 'ProximaNovaA-Regular';
            text-align: center;
            margin: 5px;
            font-size: 22px;
            color: #222;
        }
    }
    .login-language{
        font-size: 18px;
    }
    #sign-up-question{
        font-size: 22px;
        padding:25px;
        color: #222;
        &:after{
            border-bottom: solid 1px #222;
            content: '';
            position: absolute;
            width: 72px;
            top: 42px;
            right: 24px;
            @media (max-width: 767px) {
                display: none;
            }
        }
        &:before{
            border-bottom: solid 1px #222;
            content: '';
            position: absolute;
            width: 72px;
            top: 42px;
            left: 24px;
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
}


#select2-order-transport_type-results{
    .select2-results__group{
        font-size: 17px;
        background-color: #ddd;
        text-transform: uppercase;
    }
}


@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;
  
  &--dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #000;
    border-radius: 10px;
    display: inline-block;
    height: 10px;
    width: 10px;
    
    &:nth-child(2) {
      animation-delay: .5s;
    }
    
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}